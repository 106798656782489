<template>
  <div>
    <div class="platform-buttom-list">
      <div
        :class="tagType===1? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(1)"
      >设备查询</div>
      <div
        :class="tagType===2? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(2)"
      >任务列表</div>
      <div
        v-if="taskId"
        :class="tagType===3? 'bottom-button-active':'bottom-button'"
        @click="handleChangeTag(3)"
      >任务详情</div>
    </div>

    <component :is="currentComponent"></component>
  </div>
</template>

<script>

export default {
  components: {
    DeviceList: () => import('./bottom/DeviceList'),
    TaskList: () => import('./bottom/TaskList'),
    TaskDetail: () => import('./bottom/TaskDetail')
  },
  data () {
    return {
      currentComponent: 'DeviceList'
    }
  },
  computed: {
    taskId: {
      get () {
        return this.$store.state.equipment.taskId
      },
      set (val) {
        this.$store.commit('set_task_id', val)
      }

    },
    tagType () {
      return this.$store.state.equipment.tagType
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_tag_type', val)
    }
  },
  watch: {
    tagType: {
      deep: true,
      immediate: true,
      handler (val) {
        this.$store.commit('set_selected_asset_id', null)
        if (val) {
          switch (val) {
            case 1:
              this.taskId = null
              this.currentComponent = 'DeviceList'
              break
            case 2:
              this.taskId = null
              this.$store.commit('set_cur_category', null)
              this.currentComponent = 'TaskList'
              break
            case 3:
              this.$store.commit('set_cur_category', null)
              this.currentComponent = 'TaskDetail'
              break
          }
        }
      }
    }
  }
}
</script>
